import classNames from 'classnames';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import { array, arrayOf, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from 'redux';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';

import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { ensureCompanySearchOrUserType } from '../../util/data';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { getQueryParamNames, isAnyFilterActive, isOriginInUse } from '../../util/search';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_TYPE_COMPANY,
  LISTING_TYPE_TALENT,
  propTypes,
} from '../../util/types';
import { withViewport } from '../../util/uiHelpers';
import { parse } from '../../util/urlHelpers';

import { Footer, NamedRedirect, Page } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import { RESULT_PAGE_SIZE, searchListingsWithOptions, setActiveListing } from './SearchPage.duck';
import {
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validFilterParams,
  validUrlQueryParamsFromProps,
} from './SearchPage.shared';

import FilterComponent from './FilterComponent';
import LocationFilter from './LocationFilter/LocationFilter';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';
import SearchAdditionalFilters from './SearchAdditionalFilters/SearchAdditionalFilters';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SearchFiltersPrimary from './SearchFiltersPrimary/SearchFiltersPrimary';
import SearchMap from './SearchMap/SearchMap';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import SortBy from './SortBy/SortBy';
import ToggleMapButton from './ToggleMapButton';

import css from './SearchPage.module.css';

const SUPPORTED_PAGE_TYPES = [LISTING_TYPE_COMPANY, LISTING_TYPE_TALENT];

const ADDITIONAL_FILTERS_KEY = 'additionalFilters';
const NON_PRIMARY_FILTERS = [
  'graduationYear',
  'availability',
  'style',
  'secondarySpecialty',
  'experience',
  'program',
  'ad100',
  'wfhPolicy',
  'openTo',
  'interests',
  'workplacePreference',
];

const MAPBOX_MAP_CITY_TYPES = ['place'];

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

const MAX_MOBILE_SCREEN_WIDTH = 768;
const MIN_DESKTOP_SCREEN_WIDTH = 1024;

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

function moveLocationFilterToFront(filters) {
  // Find the index of the "location" filter
  const locationIndex = filters.findIndex(filter => filter.key === 'location');

  // If "location" filter is found, move it to the front
  if (locationIndex !== -1) {
    const locationFilter = filters.splice(locationIndex, 1)[0];
    filters.unshift(locationFilter);
  }

  return filters;
}

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      isAdditionalFiltersModalOpen: false,
      isMapOpen: true,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isSecondaryFiltersOpen: false,
      mapPinList: null,
    };

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    this.onOpenAdditionalFiltersModal = this.onOpenAdditionalFiltersModal.bind(this);
    this.onCloseAdditionalFiltersModal = this.onCloseAdditionalFiltersModal.bind(this);
    this.onOpenMap = this.onOpenMap.bind(this);
    this.onCloseMap = this.onCloseMap.bind(this);

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
    this.setMapPinList = this.setMapPinList.bind(this);
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = this.props.routeConfiguration;
    const searchPagePath = pathByRouteName('SearchPage', routes, { type: this.props.params.type });
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, config } = this.props;
      const { listingFields: listingFieldsConfig } = config?.listing || {};
      const { defaultFilters: defaultFiltersConfig } = config?.search || {};

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      const originMaybe = isOriginInUse(this.props.config) ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, listingFieldsConfig, defaultFiltersConfig),
      };

      history.push(
        createResourceLocatorString(
          'SearchPage',
          routes,
          { type: this.props.params.type },
          searchParams
        )
      );
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  onOpenAdditionalFiltersModal() {
    this.setState({ isAdditionalFiltersModalOpen: true });
  }

  onCloseAdditionalFiltersModal() {
    this.setState({ isAdditionalFiltersModalOpen: false });
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig
    );
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        { type: this.props.params.type },
        search
      )
    );
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);

    // Reset state
    this.setState({ currentQueryParams: {} });
    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    if (queryParams.pub_isConnected) {
      delete queryParams.pub_isConnected;
    }

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        { type: this.props.params.type },
        queryParams
      )
    );
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            listingFieldsConfig,
            defaultFiltersConfig,
            sortConfig
          );
          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration,
              {
                type: this.props.params.type,
              },
              search
            )
          );
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, routeConfiguration } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        { type: this.props.params.type },
        queryParams
      )
    );
  }

  onOpenMap() {
    this.setState({
      isMapOpen: true,
    });
  }

  onCloseMap() {
    this.setState({
      isMapOpen: false,
    });
  }

  setMapPinList(value) {
    this.setState({ mapPinList: value });
  }

  render() {
    const {
      intl,
      listings,
      location,
      onManageDisableScrolling,
      currentUser,
      currentUserListing,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      routeConfiguration,
      config,
      params,
      viewport,
      usersWithTransactions,
      ownTalentListing,
    } = this.props;
    const isCompanySearchType = ensureCompanySearchOrUserType(params.type);
    const isCurrentUserPendingApproval =
      currentUserListing?.attributes?.state === LISTING_STATE_PENDING_APPROVAL;

    const currentUserListingType = currentUserListing?.attributes?.publicData?.type;
    const isCurrentUserOfSameType = params.type === currentUserListingType;

    if (!params.type || !SUPPORTED_PAGE_TYPES.includes(params.type)) {
      // Redirect to NotFoundPage if the search type isn't supported
      return <NamedRedirect name="NotFoundPage" />;
    } else if (isCurrentUserOfSameType) {
      // Redirect to NotFoundPage if the current user isn't the same type as the SearchPage
      return <NamedRedirect name="NotFoundPage" />;
    } else if (isCurrentUserPendingApproval) {
      // Redirect to ProfileSettingsPage if the current user is pending approval
      return <NamedRedirect name="ProfileSettingsPage" />;
    }
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
    const marketplaceCurrency = config.currency;
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isDesktopLayout = viewport.width > MIN_DESKTOP_SCREEN_WIDTH;

    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
      location.search,
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig,
      isOriginInUse(config)
    );

    const validQueryParams = validFilterParams(
      searchParamsInURL,
      listingFieldsConfig,
      defaultFiltersConfig,
      false
    );

    const defaultFilters = defaultFiltersConfig;
    const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
      listingFieldsConfig,
      activeListingTypes
    );

    const availablePrimaryFilters = [
      {
        key: 'isConnected',
        scope: 'public',
        schemaType: 'enum',
        enumOptions: [
          {
            option: 'connected',
            label: 'Connected',
          },
          {
            option: 'not-connected',
            label: 'Not Connected',
          },
        ],
        filterConfig: {
          indexForSearch: true,
          filterType: 'SelectSingleFilter',
          label: 'Connection',
          group: 'primary',
          showFor: ['company', 'talent'],
        },
      },
      ...customPrimaryFilters.filter(f => f.filterConfig.showFor?.includes(params.type)),

      ...defaultFilters,
    ];

    const availableDefaultFilters = defaultFilters.filter(f => f.key === 'keywords');
    const availableFilters = [...availablePrimaryFilters, ...availableDefaultFilters];
    const hasSecondaryFilters = !!(customSecondaryFilters && customSecondaryFilters.length > 0);

    // Selected aka active filters
    const selectedFilters = validFilterParams(
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );
    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(validQueryParams, customSecondaryFilters, [])
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
        isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
        toggleSecondaryFiltersOpen: isOpen => {
          this.setState({ isSecondaryFiltersOpen: isOpen, currentQueryParams: {} });
        },
        selectedSecondaryFiltersCount,
      }
      : {};
    const hasPaginationInfo = !!pagination && pagination.totalItems != null;

    // New condition filter , not same as sharetribe original filter
    const isCompanyUser = ensureCompanySearchOrUserType(params.type);
    const userLoginName = !isCompanySearchType
      ? currentUser?.attributes?.profile?.firstName || ''
      : ownTalentListing && Object.keys(ownTalentListing).length > 0 ? ownTalentListing : undefined;
    const { pub_isConnected: connectedSearch } = searchParams;
    let filteredListing = [];
    const displayListing = !isCompanyUser
      ? userLoginName ? listings.filter(
        list =>
          list.attributes?.publicData?.company?.toLowerCase() !== userLoginName?.toLowerCase()
      ) : listings
      : userLoginName ? listings.filter(
        list => list.attributes?.title?.toLowerCase() !== userLoginName?.toLowerCase()
      ) : listings;
    //Custom connected filter
    if (connectedSearch) {
      const filterConnectedListing = displayListing.filter(list => {
        //connected return same author id else return not same author id
        return connectedSearch === 'connected'
          ? usersWithTransactions?.find(user => user.id.uuid === list.author.id.uuid)
          : !usersWithTransactions?.find(user => user.id.uuid === list.author.id.uuid);
      });
      filteredListing = [...filterConnectedListing];
    } else {
      filteredListing = [...displayListing];
    }

    const totalItems =
      searchParamsAreInSync && hasPaginationInfo
        ? filteredListing.length
        : pagination?.paginationUnsupported
          ? listings.length
          : 0;
    const listingsAreLoaded =
      !searchInProgress &&
      searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);

    const conflictingFilterActive = isAnyFilterActive(
      sortConfig.conflictingFilters,
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );
    const sortBy = mode => {
      return sortConfig.active ? (
        <SortBy
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          mode={mode}
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };

    const hasNoResult = listingsAreLoaded && totalItems === 0;
    const noResultsInfo = (
      <NoSearchResultsMaybe
        listingsAreLoaded={listingsAreLoaded}
        totalItems={totalItems}
        location={location}
        resetAll={this.resetAll}
      />
    );

    const { bounds, origin } = searchParamsInURL || {};
    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config,
      params.type
    );

    const handleSearchListingsWithOptions = () => {
      const perPage = listings.length + RESULT_PAGE_SIZE;
      this.props.onSearchListingsWithOptions(params, location.search, config, perPage, currentUser);
    };

    const filtersWithLocationFirst = moveLocationFilterToFront(availablePrimaryFilters);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
        />
        <MainPanelHeader
          className={css.mainPanelMapVariant}
          isCompanySearchType={isCompanySearchType}
        >
          <SearchFiltersPrimary {...propsForSecondaryFiltersToggle}>
            {filtersWithLocationFirst.map(config => {
              const isKeywordFilter = config.key === 'keywords';
              const menuLabelClassNameMaybe = isCompanySearchType
                ? {}
                : { menuLabelClassName: css.filtersMenuLabel };
              const onOpenAdditionalFiltersModalMaybe =
                config.key === ADDITIONAL_FILTERS_KEY
                  ? { onOpenAdditionalFiltersModal: this.onOpenAdditionalFiltersModal }
                  : {};

              if (NON_PRIMARY_FILTERS.includes(config.key)) {
                return null;
              } else if (!isDesktopLayout && config.key !== 'location') {
                return null;
              }

              return (
                <FilterComponent
                  key={`SearchFiltersPrimary.${config.key}`}
                  idPrefix="SearchFiltersPrimary"
                  locationFilterClassName={css.locationFilter}
                  locationFilterInputClassName={css.locationFilterInput}
                  locationFilterPredictionsClassName={css.locationFilterPredictions}
                  config={config}
                  marketplaceCurrency={marketplaceCurrency}
                  urlQueryParams={validQueryParams}
                  initialValues={initialValues(this.props, this.state.currentQueryParams)}
                  getHandleChangedValueFn={this.getHandleChangedValueFn}
                  intl={intl}
                  showAsPopup={!isKeywordFilter}
                  {...menuLabelClassNameMaybe}
                  {...onOpenAdditionalFiltersModalMaybe}
                />
              );
            })}
          </SearchFiltersPrimary>
        </MainPanelHeader>
        {this.state.isMapOpen ? (
          <LocationFilter
            className={css.locationSearch}
            types={MAPBOX_MAP_CITY_TYPES}
            isMobile={isMobileLayout}
            useCityPredictions
            onSubmit={this.getHandleChangedValueFn(true)} // useHistoryPush = true
          />
        ) : null}
        <ToggleMapButton
          isMapOpen={this.state.isMapOpen}
          hasNoResult={hasNoResult}
          onCloseMap={this.onCloseMap}
          onOpenMap={this.onOpenMap}
        />
        <div className={css.container}>
          {this.state.isMapOpen ? (
            <div className={css.mapWrapper} data-testid="searchMapContainer">
              <SearchMap
                reusableContainerClassName={css.map}
                activeListingId={activeListingId}
                bounds={bounds}
                center={origin}
                isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                location={location}
                listings={filteredListing || []}
                onMapMoveEnd={this.onMapMoveEnd}
                onCloseAsModal={() => {
                  onManageDisableScrolling('SearchPage.map', false);
                }}
                messages={intl.messages}
                type={params.type}
                setMapPinList={this.setMapPinList}
              />
            </div>
          ) : null}
          <div className={css.searchResultContainer}>
            <SearchFiltersMobile
              className={css.searchFiltersMobileMap}
              urlQueryParams={validQueryParams}
              sortByComponent={sortBy('mobile')}
              resultsCount={totalItems}
              showAsModalMaxWidth={MODAL_BREAKPOINT}
              onMapIconClick={() => this.setState({ isSearchMapOpenOnMobile: true })}
              onManageDisableScrolling={onManageDisableScrolling}
              onOpenModal={this.onOpenMobileModal}
              onCloseModal={this.onCloseMobileModal}
              resetAll={this.resetAll}
              type={params.type}
            >
              {availableFilters
                .filter(f => f.key !== 'keywords')
                .map(config => {
                  if (config.key === 'additionalFilters' || config.key === 'location') {
                    return null;
                  }
                  return (
                    <FilterComponent
                      key={`SearchFiltersMobile.${config.key}`}
                      idPrefix="SearchFiltersMobile"
                      locationFilterClassName={css.locationFilter}
                      locationFilterInputClassName={css.locationFilterInput}
                      locationFilterPredictionsClassName={css.locationFilterPredictions}
                      config={config}
                      marketplaceCurrency={marketplaceCurrency}
                      urlQueryParams={validQueryParams}
                      initialValues={initialValues(this.props, this.state.currentQueryParams)}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      intl={intl}
                      liveEdit
                      showAsPopup={false}
                    />
                  );
                })}
            </SearchFiltersMobile>

            <div
              className={classNames(css.listingsForMapVariant, {
                [css.newSearchInProgress]: !(listingsAreLoaded || searchListingsError),
              })}
            >
              {searchListingsError ? (
                <p className={css.error}>
                  <FormattedMessage id="SearchPage.searchError" />
                </p>
              ) : null}
              {!isValidDatesFilter ? (
                <p className={css.error}>
                  <FormattedMessage id="SearchPage.invalidDatesFilter" />
                </p>
              ) : null}
              {noResultsInfo}

              {!searchListingsError ? (
                <SearchResultsPanel
                  className={css.searchListingsPanel}
                  listings={filteredListing}
                  pagination={listingsAreLoaded ? pagination : null}
                  search={parse(location.search)}
                  setActiveListing={onActivateListing}
                  isMapOpen={this.state.isMapOpen}
                  type={params.type}
                  onSearchListingsWithOptions={handleSearchListingsWithOptions}
                  mapPinList={this.state.mapPinList}
                  usersWithTransactions={usersWithTransactions}
                  userLoginName={userLoginName}
                  connectedSearch={connectedSearch}
                />
              ) : null}
            </div>
          </div>
        </div>
        <SearchAdditionalFilters
          urlQueryParams={validQueryParams}
          resultsCount={totalItems}
          onManageDisableScrolling={onManageDisableScrolling}
          isOpen={this.state.isAdditionalFiltersModalOpen}
          onOpenModal={this.onOpenAdditionalFiltersModal}
          onCloseModal={this.onCloseAdditionalFiltersModal}
          resetAll={this.resetAll}
          type={params.type}
        >
          <div className={css.additionalFilters}>
            {availableFilters
              .filter(f => f.key !== 'keywords')
              .map(config => {
                if (config.key === 'additionalFilters' || config.key === 'location') {
                  return null;
                }
                return (
                  <FilterComponent
                    key={`AdditionalFilters.${config.key}`}
                    idPrefix="AdditionalFilters"
                    locationFilterClassName={css.locationFilter}
                    locationFilterInputClassName={css.locationFilterInput}
                    locationFilterPredictionsClassName={css.locationFilterPredictions}
                    config={config}
                    marketplaceCurrency={marketplaceCurrency}
                    urlQueryParams={validQueryParams}
                    initialValues={initialValues(this.props, this.state.currentQueryParams)}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    intl={intl}
                    useBorder
                    liveEdit
                    showAsPopup={false}
                    isCompanyUser={isCompanyUser}
                  />
                );
              })}
          </div>
        </SearchAdditionalFilters>
        <Footer className={css.footer} />
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <SearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { currentUser, currentUserListing } = state.user;
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    usersWithTransactions,
    ownTalentListing,
  } = state.SearchPage;
  const listings = getListingsById(state, currentPageResultIds);
  return {
    currentUser,
    currentUserListing,
    listings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    usersWithTransactions,
    ownTalentListing,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onSearchListingsWithOptions: (params, search, config, perPage) =>
    dispatch(searchListingsWithOptions(params, search, config, perPage)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withViewport,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedSearchPage);

export default SearchPage;
